import { Button } from '@cfa/react-components';

import Section from 'src/components/Section';
import SectionHeader from 'src/components/SectionHeader';

interface Props {
  location?: Location;
}

const FullScreenError: React.FC<Props> = ({ location = window.location }) => {
  const setLocationToOrigin = () => {
    location.href = location.origin;
  };

  return (
    <Section>
      <SectionHeader>Oops!</SectionHeader>
      <p>Your session is expiring. Please refresh to login again.</p>
      <Button onClick={setLocationToOrigin}>Refresh</Button>
    </Section>
  );
};

export default FullScreenError;
