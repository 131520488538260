import { Box, Flex } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { formatModifier, formatModifierPrice } from '../../util/format';

function OrderSummaryModifiers({ item, side, dessert, showPrices }) {
  const { modifiers } = item;

  const renderComboItem = (comboItem) =>
    comboItem &&
    comboItem.name && (
      <Flex
        className="modifier-group selected-combo-item"
        key={`${comboItem.tag || comboItem.itemTag}-order-summary`}
      >
        <Box ml="4.1%" textAlign="left" width={6.5 / 12}>
          {comboItem.name}
        </Box>
        <Box textAlign="center" width={3.5 / 12}>
          {comboItem.displayQuantity}
        </Box>
        {showPrices && (
          <Box textAlign="right" width={1.5 / 12}>
            {formatModifierPrice(comboItem.price)}
          </Box>
        )}
      </Flex>
    );

  return (
    <StyledOrderSummaryModifiers>
      {modifiers &&
        modifiers.map((mod) => {
          //this is added because api gives us discount with modifiers which have the same tag and it duplicates modifier keys
          let modTag = mod?.tag;
          if (mod.tag && typeof mod.tag === 'string') {
            const checkTag = modTag.slice(0, 5);
            if (checkTag === 'Local') {
              modTag = mod.itemNumber;
            }
          }
          return (
            //mod.itemNumber is added in case when we have tax items which modifiers don't have tags
            <Flex className="modifier-group" key={`${modTag || mod.itemNumber}-order-summary`}>
              <Box ml="4.1%" textAlign="left" width={6.5 / 12}>
                {formatModifier(mod)}
              </Box>
              <Box textAlign="center" width={3.5 / 12}>
                {mod.displayQuantity || mod.quantity}
              </Box>
              {showPrices && (
                <Box textAlign="right" width={1.5 / 12}>
                  {formatModifierPrice(mod.price)}
                </Box>
              )}
            </Flex>
          );
        })}
      {renderComboItem(side)}
      {renderComboItem(dessert)}
      <Box className="specials" color="primary" ml="4.1%">
        {item.specialInstructions || ''}
      </Box>
    </StyledOrderSummaryModifiers>
  );
}

const StyledOrderSummaryModifiers = styled.div`
  font: ${(props) => props.theme.regularTextFont};

  & .modifier-group,
  .selected-combo-item,
  .specials {
    line-height: 27px;
  }
`;

OrderSummaryModifiers.propTypes = {
  item: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  ),
  side: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  ),
  dessert: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  ),
  showPrices: PropTypes.bool,
};

OrderSummaryModifiers.defaultProps = {
  side: {},
  dessert: {},
  showPrices: true,
  item: {},
};

export default OrderSummaryModifiers;
